import { ReactNode, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import axios from 'axios';
import Cookies, { set } from 'js-cookie';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import LazyHydrate from 'react-lazy-hydration';
import { useDispatch, useSelector } from 'react-redux';
import { setPrescriptions } from 'redux/features/prescription/prescriptionSlice';
import { CREATE_USER_CART } from '../gqlStrings/mutations/addToCart';
import {
  logout,
  openAuth,
  setAuthToken,
  setAuthTokens,
  setGuestAuthToken,
  setGuestAuthTokens,
  setShoppingCartLoginEmail,
  setShoppingCartLoginModal,
  setShoppingCartLoginToken,
  setUser
} from '../redux/features/auth/authSlice';
import { startQueue, updateBag } from '../redux/features/bag/bagSlice';
import {
  setCountry,
  setRegionSelection,
  setSelectedRegion
} from '../redux/features/region/regionSlice';
import { RootState } from '../redux/store';
import Footer from './Footer/Footer';
import Navbar from './Navbar/Navbar';
import Cart from './Cart';
import { useProductStore } from 'zus/productStore';
import { useCheckoutStore } from 'zus/checkoutStore';
import { getSession } from 'functions/session';
import { getWelcomeCampaing } from 'constants/welcomeCampaing';
//import ReferralModal from './Others/ReferralModal';

const ModalNewsletter = dynamic(() => import('./Others/Welcome30Modal'));
const FingerPrintLazy = dynamic(() => import('./FingerPrintLazy'));
const ShoppingCartLogin = dynamic(() => import('./AuthModal/ShoppingCartLogin'));
const RegionSelectionModal = dynamic(() => import('./RegionSelection/RegionSelectionModal'));

let setNewsletterTimeOut: any;

const Layout = ({ children }: { children: ReactNode }) => {
  const isLogin = useSelector((state: RootState) => state.authEvent.isLogin);
  const user = useSelector((state: RootState) => state.authEvent.user);
  const shoppingCartLoginModal = useSelector((state: RootState) => state.authEvent.shoppingCartLoginModal);
  const setPaymentIntent = useCheckoutStore((state) => state.setPaymentIntent);

  const authTokens = useSelector(
    (state: RootState) => state.authEvent.authTokens
  );
  const authToken = useSelector(
    (state: RootState) => state.authEvent.authToken
  );
  const guestAuthTokens = useSelector(
    (state: RootState) => state.authEvent.guestAuthTokens
  );

  //const remember = useSelector((state: RootState) => state.authEvent.remember);
  const regions = useSelector((state: RootState) => state.regionEvent.regions);
  const selectedRegion = useSelector(
    (state: RootState) => state.regionEvent.selectedRegion
  );
  /*const navbarRedirect = useSelector(
    (state: RootState) => state.regionEvent.navbarRedirect
  );*/
  const IsAuthModalOpen = useSelector(
    (state: RootState) => state.authEvent.IsAuthModalOpen
  );
  const IsNewsletterModal = useSelector(
    (state: RootState) => state.authEvent.IsNewsletterModal
  );



  //const notRedirectRoute = ['/single-click', '/book-an-optometrist', '/book-an-eye-exam', '/union50'];

  const dispatch = useDispatch();

  const router = useRouter();
  const [importModalNewsletter, setImportModalNewsletter] = useState(false);

  const [importModalRegionChange, setImportModalRegionChange] = useState(false);


  const [openModal, setOpenModal] = useState(false);
  const isActiveLensSelectionPanel = useProductStore((state: any) => state.isActiveLensSelectionPanel);
  const importNewsletter = () => {
    if (
      router.pathname === '/' &&
      ((Cookies.get('modal_new') != 'false' && !isLogin) ||
        (Cookies.get('modal_new') != 'false' && isLogin && (('order_count' in user && Number(user.order_count) == 0)))
      )) {

      // DVS-511  
      const welcomeCampaign = getWelcomeCampaing(router.locale || 'au');
      if (welcomeCampaign.active) {
        setImportModalNewsletter(true);
        setOpenModal(true);
      }
    }
  };

  useEffect(() => {
    if (IsAuthModalOpen) {
      clearTimeout(setNewsletterTimeOut);
    } else {
      setNewsletterTimeOut = setTimeout(importNewsletter, 10000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query, IsAuthModalOpen]);

  useEffect(() => {
    if (IsNewsletterModal) {
      setImportModalNewsletter(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query, IsNewsletterModal]);


  function isTokenExpired(token: string) {
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join('')
      );
      const { exp } = JSON.parse(jsonPayload);

      return Date.now() >= exp * 1000;
    } catch (e) {
      return true;
    }
  }

  useEffect(() => {
    if (selectedRegion) {
      axios.defaults.headers.common['region'] = selectedRegion.iso;
      //isInternational
      const isInternational = selectedRegion.key === 'international';
      axios.defaults.headers.common['isInternational'] = isInternational.toString();
    }
  }, [selectedRegion]);

  useEffect(() => {
    const decodeCustomerLoginToken = async () => {
      const { decode: safeDecode } = await import('url-safe-base64');
      const decodedToken = atob(safeDecode(router.query.cLogin as string));
      if (!decodedToken) return;
      const internationalRegion = regions.find(
        (regionItem: any) => regionItem.key === 'international'
      );
      dispatch(setSelectedRegion(internationalRegion));
      localStorage.removeItem('checkout-store');
      const loginPayload = JSON.parse(decodedToken);
      window.localStorage.setItem('loggedAsCustomer', 'true');
      dispatch(setUser(loginPayload.user));
      dispatch(setAuthTokens(loginPayload.authTokens));
      dispatch(
        setAuthToken(
          loginPayload.authTokens.find(
            (token: any) => token.region === 'international'
          ).token
        )
      );
      dispatch(startQueue());
      dispatch(setPrescriptions([]));
      delete router.query.cLogin;

      router.replace(router);
    };

    if (router.query.cLogin) {
      decodeCustomerLoginToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query.cLogin]);

  useEffect(() => {
    const getShoppingCartLoginData = () => {
      axios.get(process.env.NEXT_PUBLIC_LARAVEL_URL + '/shoppingCartLogin?token=' + router.query.shoppingCartToken + '&id=' + router.query.shoppingCartUid)
        .then((response: any) => {
          dispatch(setShoppingCartLoginModal(true));
          dispatch(setShoppingCartLoginEmail(response.data.email));
          dispatch(setShoppingCartLoginToken(response.data.id));
          delete router.query.shoppingCartToken;
          delete router.query.shoppingCartUid;

        })
        .catch(() => {
          dispatch(openAuth());
        });
    }

    if (router.query.shoppingCartToken && !isLogin && router.query.shoppingCartUid) {
      getShoppingCartLoginData();
    }

    if (router.query.shoppingCartToken && isLogin) {
      delete router.query.shoppingCartToken;
      delete router.query.shoppingCartUid;
      router.push('/shopping-cart');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query.shoppingCartToken]);

  const [mutateCreateCart] = useMutation(CREATE_USER_CART, {
    context: { clientName: 'laravel' },
    onCompleted: () => {
      dispatch(startQueue());
    },
    onError: (error) => {
      console.log(error);
    }
  });

  function afterRedirectCart() {
    if (isLogin) {
      mutateCreateCart({
        variables: {
          input: {
            isEmpty: true
          }
        }
      });
    } else {
      dispatch(startQueue());
    }
  }

  function changeLocal(country: any) {
    const clientRegion = regions.filter(
      (region: any) => region.key === country.toLocaleLowerCase()
    );
    var clientRedirectRegion: any;

    if (clientRegion.length > 0) {
      clientRedirectRegion = clientRegion[0].key;
    } else {
      clientRedirectRegion = 'international';
    }

    if (clientRedirectRegion !== router.locale) {
      // localStorage.removeItem('woo-session');
      // localStorage.removeItem('woo-session-expiry');
      localStorage.removeItem('local-basket');
      localStorage.removeItem('checkout-store');
      setPaymentIntent(null);

      var region = regions.filter(
        (region: any) => region.key === clientRedirectRegion
      )[0];
      dispatch(setSelectedRegion(region));
      if (isLogin) {
        dispatch(
          setAuthToken(
            authTokens.filter((token: any) => token.region === region.key)[0]
              .token
          )
        );
      } else if (guestAuthTokens && guestAuthTokens.length > 0) {
        dispatch(
          setGuestAuthToken(
            guestAuthTokens.filter((token: any) => token.region === region.key)[0]
              .token
          )
        );
      }
      router
        .push(router.asPath, router.asPath, { locale: clientRedirectRegion })
        .then(() => {
          afterRedirectCart();
          dispatch(setRegionSelection(true));
        });
    } else {
      sameRegion();
    }
  }

  const sameRegion = () => {
    // localStorage.removeItem('woo-session');
    // localStorage.removeItem('woo-session-expiry');
    localStorage.removeItem('local-basket');
    localStorage.removeItem('checkout-store');
    setPaymentIntent(null);
    const guestAuthTokens = JSON.parse(localStorage.getItem('guestAuthTokens') || '[]');
    const region = regions.filter(
      (region: any) => region.key === router.locale
    )[0];
    dispatch(setSelectedRegion(region));
    if (isLogin) {
      dispatch(
        setAuthToken(
          authTokens.filter((token: any) => token.region === region.key)[0]
            .token
        )
      );
    } else if (guestAuthTokens && guestAuthTokens.length > 0) {
      dispatch(
        setGuestAuthToken(
          guestAuthTokens.filter((token: any) => token.region === region.key)[0]
            .token
        )
      );
    }
    dispatch(setSelectedRegion(region));
    dispatch(setRegionSelection(true));
    dispatch(startQueue());
  }

  function getCookie(name: any) {
    const value = `; ${document.cookie}`;
    const parts: any = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

  function redirectRegion() {
    if (selectedRegion == null) {
      let cookieCountry = getCookie('ucountry');

      if (cookieCountry === null || typeof cookieCountry === 'undefined') {
        if (router.locale === 'international') {
          cookieCountry = 'UK';
        }
        else {
          cookieCountry = router.locale?.toUpperCase();
        }
      }

      if (router.locale == 'international') {
        if (cookieCountry == 'US') {
          cookieCountry = 'CA';
        }

        changeLocal(cookieCountry);
      } else {
        changeLocal(router.locale);
      }

      dispatch(setCountry(cookieCountry));
    } else if (selectedRegion !== null && selectedRegion.key !== router.locale) {
      sameRegion();
    }
  }

  const createGuestCart = () => {
    fetch('/api/create-guest-cart', {
      method: 'POST',
    })
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          dispatch(setGuestAuthTokens(data.authTokens));
          setTimeout(() => {
            dispatch(setGuestAuthToken(data.authTokens.find((token: any) => token.region === router.locale).token));
          }, 1000);
        }
      });
  }

  const checkRegion = () => {
    const cookieCountry = getCookie('ucountry');
    const changeRegionFromModal = getCookie('changeRegionFromModal');
    const countryCodes = ['AU', 'CA', 'NZ'];

    if (cookieCountry && !changeRegionFromModal && router.locale) {
      const locale = router.locale.toUpperCase();
      if ((countryCodes.includes(cookieCountry) && locale === 'INTERNATIONAL') ||
        (cookieCountry !== locale && locale !== 'INTERNATIONAL')) {
        setImportModalRegionChange(true);
        // console.log('change region', cookieCountry, locale);
      } else {
        // console.log('no need to change region');
      }
    }
  };


  useEffect(() => {
    redirectRegion();
    if (isLogin && isTokenExpired(authToken)) {
      // dispatch(openAuth());
      dispatch(logout());
      dispatch(
        updateBag({
          items: []
        })
      );
    } else if (!isLogin && isTokenExpired(getSession())) {
      createGuestCart();
    }

    checkRegion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);

  useEffect(() => {
    if (!isLogin && !getSession()) {
      createGuestCart();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogin]);

  if (router.asPath.includes('dcatalogue') || router.pathname.includes('/store-registry')) {
    return <main className="flex flex-1 flex-col ">{children}</main>
  }

  return (
    <>
      {importModalNewsletter ? (
        <ModalNewsletter isopen={openModal} />
      ) : null}

      {importModalRegionChange ? (<RegionSelectionModal />) : null}

      {shoppingCartLoginModal ? (
        <ShoppingCartLogin />
      ) : null}
      <LazyHydrate whenIdle>
        <Cart />
      </LazyHydrate>
      <Navbar />
      <LazyHydrate whenIdle>
        <FingerPrintLazy />
      </LazyHydrate>
      <main className="flex flex-1 flex-col ">{children}</main>
      {!isActiveLensSelectionPanel &&
        router.pathname !== '/checkout' &&
        router.pathname !== '/checkout-complete' && (
          <LazyHydrate whenIdle>
            <Footer />
          </LazyHydrate>
        )}
    </>
  );
};

export default Layout;
