import React, { useEffect, useRef, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import {
    ADD_TO_CART,
    ADD_TO_CART_DEFAULT,
    REMOVE_CART_ITEM,
} from '../gqlStrings/mutations/addToCart';
import { RootState } from '../redux/store';
import { addToCart, addToCartContactLens, getCart, removeToCart, setPeopleAlsoBought } from 'redux/features/cart/cartSlice';
import axios from 'axios';
import { setLoading, setTotalQuantity, updateBag, updateShoppingCartWithRequest } from 'redux/features/bag/bagSlice';
import { GET_CART } from 'gqlStrings/queries/getCart';
import { logout } from 'redux/features/auth/authSlice';
import { getSession } from 'functions/session';
import { useCheckoutStore } from 'zus/checkoutStore';
import { UPDATE_CUSTOMER } from 'gqlStrings/mutations/shipping';

export default function Cart() {
    const addToCartData = useSelector((state: RootState) => state.cartEvent.addToCartData);
    const addToCartContactLensData = useSelector((state: RootState) => state.cartEvent.addToCartContactLensData);
    const removeToCartData = useSelector((state: RootState) => state.cartEvent.removeToCartData);
    const updateToCartData = useSelector((state: RootState) => state.cartEvent.updateToCartData);
    const getCartData = useSelector((state: RootState) => state.cartEvent.getCartData);
    const runQueue = useSelector((state: RootState) => state.bagEvent.runQueue);
    const peopleAlsoBought = useSelector((state: RootState) => state.cartEvent.peopleAlsoBought);

    const authToken = useSelector((state: RootState) => state.authEvent.authToken);

    const selectedRegion = useSelector(
        (state: RootState) => state.regionEvent.selectedRegion
    );
    const dispatch = useDispatch();
    const router = useRouter();
    const [addToCartDefaultMutation] = useMutation(ADD_TO_CART_DEFAULT, {
        context: { locale: selectedRegion?.key },
        onCompleted: () => {
            sendCustomerToCartPage();
        },
        onError: (error) => {
            console.log(error);
        }
    });

    const [addToCartContactLensMutation] = useMutation(ADD_TO_CART_DEFAULT, {
        context: { locale: selectedRegion?.key },
    });

    const [addToCartMutation] = useMutation(ADD_TO_CART, {
        context: { locale: selectedRegion?.key },
        onCompleted: () => {
            sendCustomerToCartPage();
        },
        onError: (error) => {
            console.log(error);
        }
    });

    const [removeMutation] = useMutation(REMOVE_CART_ITEM, {
        context: { locale: selectedRegion?.key },
        onCompleted: () => {

        },
        onError: (error) => {
            console.log(error);
        }
    });

    const [getCartMuatation] = useLazyQuery(GET_CART, {
        context: { locale: selectedRegion?.key },
        onCompleted: (getCartData) => {
            if (getCartData.cart !== null) {

                if (getCartData && router.pathname === '/shopping-cart') {
                    const cart = getCartData?.cart?.contents?.nodes;
                    if (cart) {
                        cart.forEach((item: any) => {
                            if (
                                item.variation?.node &&
                                item.product &&
                                item.extraData &&
                                item.extraData[1]?.value
                            ) {
                                try {
                                    const extraDataValue = item.extraData[1].value;
                                    // Check if the string looks like JSON
                                    if (!extraDataValue.startsWith('{') && !extraDataValue.startsWith('[')) {
                                        return;
                                    }
                                    
                                    try {
                                        const parseExtraData = JSON.parse(extraDataValue);
                                        if (parseExtraData) {
                                            Object.keys(parseExtraData).forEach((key) => {
                                                if (parseExtraData[key].variation_id === item.variation.node.databaseId) {
                                                    const attributes = parseExtraData[key].attributes;
                                                    if (!attributes['attribute_pa_frame-shape']) {
                                                        handleCompositeUpdate(item);
                                                    }
                                                }
                                            });
                                        }
                                    } catch (error) {
                                        console.error('Failed to parse extraData:', error);
                                        // Continue processing other items even if one fails
                                        return;
                                    }
                                } catch (error) {
                                    console.error('Failed to parse extraData:', error);
                                    // Continue processing other items even if one fails
                                    return;
                                }
                            }
                        });
                    }
                }

                dispatch(updateShoppingCartWithRequest(getCartData));
                dispatch(setLoading(false));
                dispatch(getCart(false));
            }
        },
        onError: (error) => {
            if (error.networkError && error.networkError.name == 'AbortError') {
                // Handle the aborted request case if necessary
            } else {
                // cartError();
            }
        }
    });

    const cartError = async () => {
        dispatch(logout());
        dispatch(
            updateBag({
                items: []
            })
        );
        dispatch(setLoading(true));
        //Only will work for logged in users
        await getCartMuatation();
    }

    const sendCustomerToCartPage = () => {
        if (router.pathname !== '/shopping-cart') {
            router.push('/shopping-cart');
            dispatch(addToCart(null));
            dispatch(removeToCart(null));
        } else {
            dispatch(addToCart(null));
            dispatch(removeToCart(null));
        }
        if (peopleAlsoBought) {
            dispatch(setPeopleAlsoBought(false));
        }
    }

    const handleAddToCart = (item: any) => {
        if (item.type == 'simple') {
            addToCartDefaultMutation(item);
        } else {
            addToCartMutation(item);
        }
    }

    const [frameShapeAdded, setFrameShapeAdded] = useState(false);
    const frameShapeRequestMade = useRef(false);

    const addDefaultFrameShape = async () => {
        if (!frameShapeRequestMade.current && !frameShapeAdded) {
            frameShapeRequestMade.current = true;
            try {
                await axios.post(process.env.NEXT_PUBLIC_LARAVEL_URL + '/add-default-frame-shape', {}, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + getSession()
                    }
                });
                setFrameShapeAdded(true);
            } catch (error) {
                console.error('Error adding default frame shape:', error);
                frameShapeRequestMade.current = false;
            }
        }
    }

    const handleCompositeUpdate = async (item: any) => {
        if (item.extraData && item.extraData[1]?.value) {
            const extraData = JSON.parse(item.extraData[1].value);
            const lastKey = Object.keys(extraData)[Object.keys(extraData).length - 1];
            const lensValue = extraData[lastKey];
            if (!lensValue.attributes['attribute_pa_frame-shape'] &&
                item.extraData[0]?.key === 'composite_parent') {
                try {
                    console.log('FrameShape missing');
                    addDefaultFrameShape();
                } catch (error) {
                    console.error('Error updating composite item:', error);
                }
            }
        }
    };

    useEffect(() => {
        if ((selectedRegion && getSession()) || (authToken)) {
            axios.get(
                process.env.NEXT_PUBLIC_LARAVEL_URL +
                '/getTotalQuantity',
                {
                    headers: {
                        Authorization: 'Bearer ' + getSession() //the token is a variable which holds the token
                    }
                }
            ).then((response) => {
                dispatch(setTotalQuantity(response.data));
            }).catch(() => {
                dispatch(setTotalQuantity(0));
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRegion, authToken]);

    const handleContactLensAddToCart = async (contactLensData: any) => {
        addToCartContactLensMutation(contactLensData[0]).then(() => {
            if (contactLensData.length == 2) {
                addToCartContactLensMutation(contactLensData[1]);
            }
            dispatch(addToCartContactLens([]));
            router.push('/shopping-cart');
        });

    }

    const [updateCustomer] = useMutation(UPDATE_CUSTOMER, {
        context: { locale: selectedRegion?.key },
        onCompleted: () => {
            getCartMuatation();
        }
    });

    useEffect(() => {
        if ((getCartData || !runQueue || runQueue) && (router.pathname == '/shopping-cart' || router.pathname == '/checkout') && !peopleAlsoBought) {
            dispatch(setLoading(true));

            if (router.locale !== 'international' && router.pathname == '/shopping-cart') {
                // Set default country based on locale
                const defaultAddress = {
                    country: router.locale?.toUpperCase() || 'AU', // Default to AU if locale is undefined
                    firstName: '',
                    lastName: '',
                    address1: '',
                    address2: '',
                    city: '',
                    state: '',
                    postcode: '',
                    email: '',
                    phone: ''
                };

                updateCustomer({
                    variables: {
                        input: {
                            clientMutationId: getSession(),
                            billing: defaultAddress,
                            shipping: defaultAddress
                        }
                    }
                });
            } else {
                // If international, just get cart directly
                getCartMuatation();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getCartData, runQueue, peopleAlsoBought, router.pathname, router.locale]);

    useEffect(() => {
        if (addToCartData && Object.keys(addToCartData).length > 0) {
            handleAddToCart(addToCartData);
        }

        if (removeToCartData && Object.keys(removeToCartData).length > 0) {
            removeMutation(removeToCartData);
        }

        if (updateToCartData && Object.keys(updateToCartData).length > 0) {
            handleAddToCart(addToCartData);
        }
        if ((getCartData || !runQueue || runQueue) && (router.pathname == '/shopping-cart' || router.pathname == '/checkout') && !peopleAlsoBought) {
            dispatch(setLoading(true));
            getCartMuatation();
        }

        if (addToCartContactLensData && addToCartContactLensData.length > 0) {
            handleContactLensAddToCart(addToCartContactLensData);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addToCartData, updateToCartData, removeToCartData, getCartData, runQueue, addToCartContactLensData, peopleAlsoBought]);

    return <></>;
}
