const welcomeCampaign: { [key: string]: { active: boolean, code: string, discount: number, slug: string, title: string, price: number } } = {
    'au': {
        'active': true,
        'title': 'Welcome30',
        'code': 'WELCOME30',
        'discount': 30,
        'slug': 'welcome30',
        'price': 70
    },
    'nz': {
        'active': true,
        'title': 'Welcome30',
        'code': 'WELCOME30',
        'discount': 30,
        'slug': 'welcome30',
        'price': 70
    },
    'ca': {
        'active': true,
        'title': 'Welcome30',
        'code': 'WELCOME30',
        'discount': 30,
        'slug': 'welcome30',
        'price': 70
    },
    'international': {
        'active': true,
        'title': 'Welcome30',
        'code': 'WELCOME30',
        'discount': 30,
        'slug': 'welcome30',
        'price': 70
    }
}

export const getWelcomeCampaing = (region: string) => {
    return welcomeCampaign[region];
}