import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import NProgress from 'nprogress';
import LazyHydrate from 'react-lazy-hydration';
import { RegionKey } from 'components/Header/HeaderNavigationRegionSelect';
import { CREATE_USER_CART } from 'gqlStrings/mutations/addToCart';
import { openAuth, setAuthToken, setGuestAuthToken, setInitialAuthTab } from 'redux/features/auth/authSlice';
import { startQueue } from 'redux/features/bag/bagSlice';
import {
  setNavbarRedirect,
  setRegionSelection,
  setSelectedRegion
} from 'redux/features/region/regionSlice';
import { useAppSelector, useAppDispatch } from 'redux/hooks';
import { useCheckoutStore } from 'zus/checkoutStore';
import { getSession } from 'functions/session';
import Cookies from 'js-cookie';
const AuthModalDyn = dynamic(
  async () => {
    NProgress.start();
    const { default: authModal } = await import(
      'components/AuthModal/AuthModal'
    );
    NProgress.done();
    return authModal;
  },
  {
    ssr: false
  }
);
const VerifyModal = dynamic(() => import('components/AuthModal/VerifyModal'));
const PasswordResetChange = dynamic(
  () => import('components/AuthModal/PasswordResetChange')
);
const PasswordReset = dynamic(
  () => import('components/AuthModal/PasswordReset')
);
const MobileHeader = dynamic(
  () => import('components/Header/Mobile/MobileHeader')
);

const DefaultHeader = dynamic(() => import('components/Header/Header'));

function Navbar() {
  const dispatch = useAppDispatch();
  const isBagOpenRtk = useAppSelector((state) => state.bagEvent.bagOpen);
  const selectedRegion = useAppSelector(
    (state) => state.regionEvent.selectedRegion
  );
  const loading = useAppSelector((state) => state.bagEvent.loading);
  const regions = useAppSelector((state) => state.regionEvent.regions);
  const authTokens = useAppSelector((state) => state.authEvent.authTokens);
  const guestAuthTokens = useAppSelector((state) => state.authEvent.guestAuthTokens);

  const isAuthModalOpen = useAppSelector((state) => state.authEvent.modalOpen);
  const isLogin = useAppSelector((state) => state.authEvent.isLogin);
  const paswordResetModal = useAppSelector(
    (state) => state.authEvent.passwordResetModal
  );
  const user = useAppSelector((state) => state.authEvent.user);
  const bagItemCount = useAppSelector((state) => state.bagEvent.count);
  const [counter, setCounter] = useState<number>(0);
  const [bagOpen, setBagOpen] = useState<boolean>(false);
  const [shopBagImport, setShopBagImport] = useState<boolean>(false);
  const [passwordResetModal, setPasswordResetModal] = useState<boolean>(false);
  const [totalExcute, setTotalExcute] = useState<boolean>(false);
  const setIntentId = useCheckoutStore((state) => state.setIntentId);

  const router = useRouter();
  useEffect(() => {
    if (router.query.passwordReset) {
      setPasswordResetModal(true);
    }
  }, [router.query]);

  useEffect(() => {
    setCounter(bagItemCount);
  }, [bagItemCount]);

  useEffect(() => {
    if (isBagOpenRtk) {
      setBagOpen(isBagOpenRtk);
      setShopBagImport(isBagOpenRtk);
    }
  }, [isBagOpenRtk]);

  const [mutateCreateCart] = useMutation(CREATE_USER_CART, {
    context: { clientName: 'laravel' },
    onCompleted: () => {
      dispatch(startQueue());
    },
    onError: (error) => {
      console.log(error);
    }
  });

  function afterRemoveLocalStorage() {
    if (!totalExcute && getSession() === null) {
      if (isLogin) {
        mutateCreateCart({
          variables: {
            input: {
              isEmpty: true
            }
          }
        });
      } else {
        dispatch(startQueue());
      }
      setTotalExcute(true);
    }
  }

  const [redirectPath, setRedirectPath] = useState('');

  const toggleAuthModal = (
    type: 'SIGN_IN' | 'SIGN_UP',
    redirectPath?: string
  ) => {
    setRedirectPath(redirectPath || '');
    dispatch(setInitialAuthTab(type));
    dispatch(openAuth());
  };

  const onLoginSuccess = () => {
    if (redirectPath) router.push(redirectPath as string);
    setRedirectPath('');
  };

  useEffect(() => {
    router.asPath.includes('referral-program') && setRedirectPath('/referral');
  }, [router])

  const updateRegion = async (regionKey: RegionKey) => {
    if (regionKey !== selectedRegion.key) {
      Cookies.set('changeRegionFromModal', 'true');
      const region = regions.find(
        (regionItem: any) => regionItem.key === regionKey
      );
      afterRemoveLocalStorage();

      if(region.key === 'international') {
        window.location.href = window.location.origin + router.asPath;
      } else {
        window.location.href = window.location.origin + '/' + region.key + router.asPath;
      }
    }
  };

  return (
    <>
      <LazyHydrate whenVisible>
        <DefaultHeader
          basketItemCount={counter}
          isBasketReady={!loading}
          showAuthModal={toggleAuthModal}
          updateRegion={updateRegion}
          toggleBasketOverlay={() => {
            router.push('/shopping-cart');
          }}
        />
      </LazyHydrate>
      <LazyHydrate whenVisible>
        <MobileHeader
          basketItemCount={counter}
          isBasketReady={!loading}
          showAuthModal={toggleAuthModal}
          updateRegion={updateRegion}
          toggleBasketOverlay={() => {
            router.push('/shopping-cart');
          }}
        />
      </LazyHydrate>
      {isAuthModalOpen ? (
        <AuthModalDyn
          onLoginSuccess={onLoginSuccess}
        />
      ) : null}
      {isLogin && user.verify == 'unverify' && !isAuthModalOpen ? <VerifyModal /> : null}
      {paswordResetModal && <PasswordReset />}
      {passwordResetModal && <PasswordResetChange />}
    </>
  );
}

export default React.memo(Navbar);
