import parse from 'html-react-parser';
import { GoogleReviewModel, PageHeaderData, SeoBlock, SeoContent, SeoFaq, SeoMetaTags, SeoRemoteBlock, SeoRemoteFaq, CategoryBannerImage } from "constants/dataTypes";
import { regionlistToLocale } from 'functions/notionFixRegion';
import { CategoryBannerProps } from 'components/CategoryBanner/CategoryBanner';
import { ArmsOptionWithHex, ColorSize, ColorSizeWithHex, FrameOptionWithHex, ProductOptions, ProductOptionsWithHex } from 'constants/dataDresdenTypes';
import { slugToString } from 'functions/slug';
import { CategoryData } from 'types/category';
import { getWelcomeCampaing } from 'constants/welcomeCampaing';

interface PageBanner {
  id: number;
  ecommerce_page_id: number;
  sort_index: number | null;
  type: string;
  background_image: string | null;
  background_video: string | null;
  mobile_background_image: string | null;
  mobile_background_video: string | null;
  background_color: string | null;
  title: string | null;
  title_color: string | null;
  subtitle: string | null;
  subtitle_color: string | null;
  button_text: string | null;
  button_color: string | null;
  button_text_color: string | null;
  button_url: string | null;
  alt_text: string | null;
  title_widths: string | null;
  mobile_subtitle: string | null;
  mobile_title: string | null;
  publish_start_date: string | null;
  publish_end_date: string | null;
  regions: string[];
  primary?: {
    desktop: string;
    mobile: string;
    href: string;
  };
}

interface PageBannersResponse {
  id: number;
  slug: string;
  category: string;
  is_dynamic_page: number;
  regions: string[];
  page_banners: PageBanner[];
}

export const getSeoMetaTags = async (slug: string, locale: string | undefined): Promise<SeoMetaTags | null> => {

  if (slug.startsWith('/')) {
    slug = slug.slice(1);
  }

  if (slug.startsWith('shop/non-prescription-sunglasses')) {
    slug = slug.replace('shop/non-prescription-sunglasses', 'shop/sunglasses');
  }

  // wc-api-dev-bedrock.dresden.vision/ecommerce-data/page-seo?slug=shop/prescription-glasses/shape/round&region=international
  const metaUrl = `${process.env.NEXT_PUBLIC_LARAVEL_URL}/ecommerce-data/page-seo?slug=${slug}&region=${locale}`;

  // console.log('slug: ', slug, ', locale:', locale, ', url :', metaUrl);
  const seoRequest = await fetch(metaUrl, { cache: 'no-store' });

  if (seoRequest.status !== 200) {
    return null;
  }

  const response = await seoRequest.json();

  if (response && response.seo_meta && response.seo_meta.length > 0) {
    const seoMetaTags: SeoMetaTags[] = response.seo_meta;
    return seoMetaTags[0];
  }

  return null;
}


export const filterNotionDataByPublishDate = async (data: PageHeaderData[]): Promise<PageHeaderData | null> => {
  // Use Promise.all to wait for all filterNotionPageData promises to resolve

  if (!data.length) return null;

  let filterData: PageHeaderData | null = null;

  data.map((dataRow: PageHeaderData) => {
    //If publishDate.start is in the past then add to filterData
    if (dataRow.publishDate && dataRow.publishDate.start) {
      if (new Date(dataRow.publishDate.start) <= new Date()) {
        if (dataRow.publishDate.end) {
          if (new Date(dataRow.publishDate.end) > new Date()) {
            filterData = dataRow;
          }
        }
        else { //no end date
          filterData = dataRow;
        }
      }

    }
  });

  if (filterData) return filterData;

  data.map((dataRow: PageHeaderData) => {
    //If publishDate.start is in the past then add to filterData
    if (dataRow.publishDate == null) {
      filterData = dataRow;

    }
  });

  return filterData;


}


export const getRelativeTime = (dateString: string) => {
  const date = new Date(dateString);
  const now = new Date();
  const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);

  const intervals = [
    { label: 'year', seconds: 31536000 },
    { label: 'month', seconds: 2592000 },
    { label: 'week', seconds: 604800 },
    { label: 'day', seconds: 86400 },
    { label: 'hour', seconds: 3600 },
    { label: 'minute', seconds: 60 },
    { label: 'second', seconds: 1 },
  ];

  for (const interval of intervals) {
    const count = Math.floor(diffInSeconds / interval.seconds);
    if (count >= 1) {
      return `${count} ${interval.label}${count !== 1 ? 's' : ''} ago`;
    }
  }

  return 'Just now';
};
export const getFrameArmsOptions = async (locale: string | undefined, fileName: string = 'frame_arms_options'): Promise<ProductOptionsWithHex | null> => {
  // Construct the data path based on locale
  const dataPath = `${process.env.NEXT_PUBLIC_S3_DATA_URL}${locale !== 'international' ? `/${locale}` : '/au'}/ecommerce/glasses/${fileName}.json`;
  try {
    // Fetch the data with no caching
    const response = await fetch(dataPath, { cache: 'no-store' });

    // If the response is not OK, return null
    if (!response.ok) {
      console.error(`Failed to fetch getFrameArmsOptions data. Status: ${response.status}`);
      return null;
    }

    // Parse the JSON data
    const data: ProductOptions = await response.json();

    // Create a color to hex map for quick lookup
    const colorHexMap: Record<string, string> = {};
    data.colorLookup.forEach((entry) => {
      colorHexMap[entry.color] = `#${entry.hex}`;
    });

    // Helper function to append hex to ColorSize
    const appendHexToColorSize = (color_size: ColorSize[]): ColorSizeWithHex[] => {
      return color_size.map((cs) => ({
        ...cs,
        name: slugToString(cs.color),
        slug: cs.color,
        hex: colorHexMap[cs.color] || '#000000', // Default to black if hex not found
      }));
    };

    // Transform frame_options by adding hex to each color_size
    const transformedFrameOptions: FrameOptionWithHex[] = data.frame_options.map((frameOption) => ({
      shape: frameOption.shape,
      color_size: appendHexToColorSize(frameOption.color_size),
    }));

    // Transform arms_options by adding hex to each color
    const transformedArmsOptions: ArmsOptionWithHex[] = data.arms_options.map((armsOption) => ({
      name: slugToString(armsOption.color),
      slug: armsOption.color,
      hex: colorHexMap[armsOption.color] || '#000000', // Default to black if hex not found
      size_list: armsOption.size_list,
    }));

    // Construct the new ProductOptionsWithHex object
    const transformedData: ProductOptionsWithHex = {
      frame_options: transformedFrameOptions,
      arms_options: transformedArmsOptions,
    };

    return transformedData;
  } catch (error) {
    console.error('Error fetching or processing data:', error);
    return null;
  }
};
export const getGoogleReviews = async (locale: string | undefined): Promise<GoogleReviewModel | null> => {

  const localeSuffix = locale == 'ca' ? 'ca' : 'au';

  const dataUrl = `${process.env.NEXT_PUBLIC_NOTION_S3_URL}/googlereviews/reviews_${localeSuffix}.json`;
  //console.log('dataUrl', dataUrl);
  const request = await fetch(dataUrl, { cache: 'no-store' });

  if (request.status !== 200) {
    return null;
  }

  const data: GoogleReviewModel = await request.json();

  return data;
}


export const getPageHeaderData = async (slug: string, locale: string | undefined, pageBannerProps: CategoryBannerProps): Promise<CategoryBannerProps | null> => {
  const localeSuffix = '&region=' + locale;
  const dataUrl = `${process.env.NEXT_PUBLIC_LARAVEL_URL}/ecommerce-data/page-headers?slug=${slug}${localeSuffix}`;

  const request = await fetch(dataUrl, { cache: 'no-store' });

  if (request.status !== 200) {
    return pageBannerProps;
  }

  let data: PageHeaderData[] = await request.json();

  if (process.env.NEXT_PUBLIC_ENV == 'production') {
    data = data.filter((item: PageHeaderData) => item.publishStatus == 'Production');
  } else {
    //remove items that have publishStatus != process.env.NEXT_PUBLIC_ENV
    const devData = data.filter((item: PageHeaderData) => item.publishStatus == 'Development');
    if (devData.length > 0) {
      data = devData;
    }
  }

  const pageHeaderRow: PageHeaderData | null = await filterNotionDataByPublishDate(data);

  if (!pageHeaderRow) return pageBannerProps;

  const categoryBannerProps: CategoryBannerProps = {
    title: pageHeaderRow?.title || '',
    description: '<h1>' + pageHeaderRow?.title + '</h1>' + pageHeaderRow?.content || '',
    headerBackgroundColour: '',
    videoSrc: pageHeaderRow?.video || '',
    imageSrc: pageHeaderRow?.bannerImage || '',
    imageAlt: pageHeaderRow?.bannerImageAlt || '',
    breadcrumbItems: pageBannerProps.breadcrumbItems || []
  }

  return categoryBannerProps;
}

export const getCategoryBannerImages = async (slug: string, locale: string | undefined): Promise<CategoryBannerImage[]> => {
  const localeSuffix = locale == 'international' ? '' : '_' + locale;
  const dataUrl = `${process.env.NOTION_S3_URL}/pgcategorybanners/${slug}${localeSuffix}.json`;
  //console.log('dataUrl', dataUrl);
  try {
    const request = await fetch(dataUrl, { cache: 'no-store' });

    if (request.status !== 200) {
      return [];
    }

    let data: CategoryBannerImage[] = await request.json();

    // Filter by publish status based on environment
    if (process.env.NEXT_PUBLIC_ENV === 'production') {
      data = data.filter((item) => item.publishStatus === 'Production');
    } else {
      const devData = data.filter((item) => item.publishStatus === 'Development');
      if (devData.length > 0) {
        data = devData;
      }
    }

    // Filter by publish date
    data = data.filter((item) => {
      if (!item.publishDate) return true;

      const now = new Date();
      const startDate = new Date(item.publishDate.start);

      if (startDate > now) return false;

      if (item.publishDate.end) {
        const endDate = new Date(item.publishDate.end);
        if (endDate < now) return false;
      }

      return true;
    });

    // Sort by orderIndex
    return data.sort((a, b) => a.orderIndex - b.orderIndex);
  } catch (error) {
    console.error('Error fetching category banner images:', error);
    return [];
  }
}

export const getSeoBlockContent = async (categorySlug: string | undefined, locale: string | undefined): Promise<SeoBlock | null> => {
  let categorySeoText = '';
  const seoFaqList: SeoFaq[] = [];

  if (!categorySlug) {
    return { text: categorySeoText, faq: seoFaqList };
  }

  if (categorySlug.startsWith('/')) {
    categorySlug = categorySlug.slice(1);
  }

  try {
    if (categorySlug.startsWith('shop/non-prescription-sunglasses')) {
      categorySlug = categorySlug.replace('shop/non-prescription-sunglasses', 'shop/sunglasses');
    }

    const seoContentURL = `${process.env.NEXT_PUBLIC_LARAVEL_URL}/ecommerce-data/page-seo?slug=${categorySlug}&region=${locale}`;
    const contents = await fetch(seoContentURL);

    if (contents.status !== 200) {
      return { text: categorySeoText, faq: seoFaqList };
    }

    const seoContentBlock = await contents.json();
    if (!seoContentBlock) {
      return { text: categorySeoText, faq: seoFaqList };
    }

    // Handle SEO footer content
    if (seoContentBlock.seo_footer && seoContentBlock.seo_footer.length > 0) {
      categorySeoText = seoContentBlock.seo_footer[0].content || '';
    }

    // Handle FAQ content
    if (seoContentBlock.seo_faqs) {
      seoContentBlock.seo_faqs
        .sort((a: any, b: any) => a.faq_index - b.faq_index)
        .forEach((faq: any) => {
          seoFaqList.push({
            question: faq.faq_title,
            answer: faq.faq_content
          });
        });
    }

    // Update URLs based on locale
    if (locale === 'international') {
      categorySeoText = categorySeoText.replace(/href="\//g, 'href="https://www.dresden.vision/');
    } else {
      categorySeoText = categorySeoText.replace(/href="\//g, 'href="https://dresden.vision/' + locale + '/');
    }

    //     $extraCssClasses = ['paragraph' => 'py-2', 'heading_2' => 'st-blog-p-title', 'heading_3' => 'st-blog-p-title', 'bulleted_list_item' => 'py-2', 'numbered_list_item' => 'py-2'];

    // Map the tag names to actual HTML tags
    const extraCssClasses = {
      'p': 'py-2', // paragraph
      'h2': 'seo-title-1', // heading_2
      'h3': 'seo-title-2', // heading_3
      'h4': 'seo-title-3', // heading_4
      'ul': 'list-disc pl-6'   // lists
    };

    // Apply style classes to category SEO text
    categorySeoText = applyStyleClassesToHtml(categorySeoText, extraCssClasses);

    // Apply the same style classes to each FAQ answer
    seoFaqList.forEach(faq => {
      faq.answer = applyStyleClassesToHtml(faq.answer, extraCssClasses);
    });

    return { text: categorySeoText, faq: seoFaqList };

  } catch (error) {
    console.error('getSeoBlockContent error', error);
    return { text: categorySeoText, faq: seoFaqList };
  }
};

/**
 * Applies CSS classes to HTML elements in content
 * @param htmlContent The HTML content to process
 * @param classMapping Object mapping HTML tags to CSS classes
 * @returns Processed HTML content with CSS classes applied
 */
const applyStyleClassesToHtml = (htmlContent: string, classMapping: Record<string, string>): string => {
  if (!htmlContent) return '';

  let processedHtml = htmlContent;

  Object.entries(classMapping).forEach(([tag, cssClass]) => {
    // Handle tags with no attributes
    processedHtml = processedHtml.replace(
      new RegExp(`<${tag}>`, 'g'),
      `<${tag} class="${cssClass}">`
    );

    // Handle tags with attributes but don't have any class attribute
    processedHtml = processedHtml.replace(
      new RegExp(`<${tag}\\s+(?!.*?class=)([^>]*)>`, 'g'),
      `<${tag} class="${cssClass}" $1>`
    );

    // Handle tags that have a class attribute - update existing class attribute
    // rather than adding a new one
    processedHtml = processedHtml.replace(
      new RegExp(`<${tag}\\s+(.*?)class=["']([^"']*)["'](.*?)>`, 'g'),
      (match, before, classValue, after) => {
        // Only add our class if it's not already present
        if (!classValue.split(/\s+/).includes(cssClass)) {
          return `<${tag} ${before}class="${classValue} ${cssClass}"${after}>`;
        }
        return match; // Return unchanged if our class is already present
      }
    );
  });

  return processedHtml;
};

export const formatVisiblePrice = (
  value: number,
  currencySymbol = '$',
  fractionsLength = 2
) => {
  if (!value && value !== 0) return '';

  if (typeof value !== 'number') return currencySymbol + value;

  const isFixed = value % 1 === 0;

  if (!isFixed) return currencySymbol + value.toFixed(fractionsLength);

  return currencySymbol + value;
};

export const parsePiwikId = () => {
  const cookies = findAllCookies();
  if (!cookies) return;

  const piwikCookieKey = Object.keys(cookies).find((cookieKey) =>
    cookieKey.startsWith('_pk_id')
  );

  if (!piwikCookieKey) return;

  const [piwikId] = cookies?.[piwikCookieKey]?.split?.('.') || [];

  return piwikId;
};

export const findAllCookies = (): Record<string, string> => {
  return document.cookie.split(';').reduce((res, c) => {
    const [key, val] = c.trim().split('=').map(decodeURIComponent);
    const allNumbers = (str: any) => /^\d+$/.test(str);
    try {
      return Object.assign(res, {
        [key]: allNumbers(val) ? val : JSON.parse(val)
      });
    } catch (e) {
      return Object.assign(res, { [key]: val });
    }
  }, {});
};

export const convertPriceTextToNumber = (text?: string) => {
  if (!text || typeof text !== 'string') return 0;
  const nsbp = String.fromCharCode(160);
  const [, priceStr] = (parse(text) as string).split?.(nsbp) || [];
  return Number(priceStr || 0);
};

export const replaceTags = (htmlContent: string, replaceValue = '') => {
  if (!htmlContent) return '';
  const replaceRegexp = /(<([^>]+)>)/gi;

  return htmlContent?.replace?.(replaceRegexp, replaceValue).trim() || '';
};

export const clearNonNumeric = (text: string) => {
  return text?.replace?.(/\D/g, '') || '';
}

export const convertCanvasToBase64 = async (options: {
  width: number;
  height: number;
  flip: boolean;
  element: HTMLCanvasElement;
  watermark: boolean;
}) => {
  const offScreenCanvas = document.createElement('canvas');
  const offScreenCanvasContext = offScreenCanvas.getContext('2d');
  const image = new Image();
  image.src = options.element.toDataURL();
  await new Promise((res) => {
    image.addEventListener('load', res);
  });

  if (!offScreenCanvasContext) throw new Error();

  offScreenCanvas.width = options.width;
  offScreenCanvas.height = options.height;

  const containerRatio = options.height / options.width;
  let width = image.naturalWidth;
  let height = image.naturalHeight;
  const imgRatio = height / width;

  if (imgRatio > containerRatio) {
    height = width * containerRatio;
  } else {
    width = height / containerRatio;
  }

  const outputSettings = {
    width: width,
    height: height,
    offsetX: (image.naturalWidth - width) * 0.5,
    offsetY: (image.naturalHeight - height) * 0.5
  };

  if (options.flip) {
    offScreenCanvasContext.translate(options.width, 0);
    offScreenCanvasContext.scale(-1, 1);
  }

  offScreenCanvasContext.drawImage(
    image,
    outputSettings.offsetX,
    outputSettings.offsetY,
    outputSettings.width,
    outputSettings.height,
    0,
    0,
    options.width,
    options.height
  );

  const imageDataURL = offScreenCanvas.toDataURL();

  if (!options.watermark) {
    return imageDataURL;
  }

  const { default: watermark } = await import('watermarkjs');

  const watermarkedImage: HTMLImageElement = await watermark([
    imageDataURL,
    '/images/logo-white.png'
  ]).image(watermark.image.lowerLeft(0.5));

  return watermarkedImage.src;
};

export const downloadURL = async (url: string, fileName: string) => {
  const offDocumentAnchorElement = document.createElement('a');
  offDocumentAnchorElement.href = url;
  offDocumentAnchorElement.download = fileName;
  document.body.appendChild(offDocumentAnchorElement);
  offDocumentAnchorElement.click();
  await new Promise((resolve) => setTimeout(resolve, 1150));
  offDocumentAnchorElement.remove();
};

export const shareBase64Image = async (dataURL: string, fileName: string) => {
  const file = await base64ToFile(dataURL, fileName);
  const payload = {
    files: [file]
  };
  // @ts-ignore
  navigator.share(payload);
};

export const base64ToFile = async (fileDataURL: string, fileName: string) => {
  const blob = await (await fetch(fileDataURL)).blob();
  return new File([blob], fileName, {
    type: blob.type,
    lastModified: new Date().getTime()
  });
};

export const canShareFile = async (fileBase64: string, fileName: string) => {
  if (
    typeof navigator.share !== 'function' ||
    // @ts-ignore
    typeof navigator.canShare !== 'function'
  )
    return false;

  const file = await base64ToFile(fileBase64, fileName);
  const payload = {
    files: [file]
  };

  // @ts-ignore
  return await navigator.canShare(payload);
};

export const findVisitorCountryFromIP = async () => {
  let visitorCountry = 'AU';

  try {
    visitorCountry = await findVisitorCountryFromCloudflare();
  } catch {
    visitorCountry = await findVisitorCountryFromCountryIs();
  }

  return visitorCountry.toUpperCase();
};

const findVisitorCountryFromCloudflare = async () => {
  const cfResponse = await fetch('https://1.1.1.1/cdn-cgi/trace');
  const cfResponseText = await cfResponse.text();
  const [, cfCountry] = cfResponseText.match(/loc=(.*)/) || [];
  return cfCountry;
};

const findVisitorCountryFromCountryIs = async () => {
  const countryIsResponse = await fetch('https://api.country.is/');
  const countryIsResponseObject = await countryIsResponse.json();
  return countryIsResponseObject.country;
};

// https://www.simoahava.com/gtm-tips/fix-rogue-referral-problem-single-page-sites/
export const getOriginalLocation = () => {
  const currentLocation = `${document.location.protocol}//${document.location.hostname}${document.location.pathname}${document.location.search}`;
  const overrideLocation = currentLocation.includes('gclid=') || currentLocation.includes('utm_');
  const savedLocation = sessionStorage.getItem('originalLocation');
  if (!savedLocation || overrideLocation) {
    sessionStorage.setItem('originalLocation', currentLocation);
    return currentLocation;
  }
  return savedLocation;
}

export const getPanelUrlByLocale = (locale: string | undefined): string => {

  if (!process.env.NEXT_PUBLIC_NEXT_APP_API_URL) {
    console.error('ERROR: NEXT_PUBLIC_NEXT_APP_API_URL is not set!');
  }

  if (locale == null) {
    return process.env.NEXT_PUBLIC_NEXT_APP_API_URL!;
  }

  if (locale === 'nz') {
    if (!process.env.NEXT_PUBLIC_NEXT_APP_API_URL_NZ) {
      console.error('ERROR: NEXT_PUBLIC_NEXT_APP_API_URL_NZ is not set!');
    }
    return process.env.NEXT_PUBLIC_NEXT_APP_API_URL_NZ!;
  }

  if (locale === 'ca') {
    if (!process.env.NEXT_PUBLIC_NEXT_APP_API_URL_CA) {
      console.error('ERROR: NEXT_PUBLIC_NEXT_APP_API_URL_CA is not set!');
    }
    return process.env.NEXT_PUBLIC_NEXT_APP_API_URL_CA!;
  }

  return process.env.NEXT_PUBLIC_NEXT_APP_API_URL!;
}

export const dateToUTCFormat = (date: Date) => (
  new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds())
);

export const getOrdinalNum = (number: any) => {
  let selector;

  if (number <= 0) {
    selector = 4;
  } else if ((number > 3 && number < 21) || number % 10 > 3) {
    selector = 0;
  } else {
    selector = number % 10;
  }

  return number + ['th', 'st', 'nd', 'rd', ''][selector];
};

export const bundleProductTagsMap: any = {
  'bundle': 'Bundle',
  '12-month-supply': '12 Month Supply',
  '6-month-supply': '6 Month Supply',
  'free-essential-kit': 'Free Essential Kit',
  'free-essential-lite-kit': 'Free Essential Lite Kit',
}

export const getUrlByLocale = (locale: string | undefined, path: string): string => {

  if (locale === 'nz' || locale === 'ca' || locale === 'au') {

    return '/' + locale + (path.startsWith('/') ? path : '/' + path);
  }

  return path;
}

export const getCategorySeoContent = async (dbID: string | undefined, categorySlug: string | undefined): Promise<string | null> => {

  if (!dbID || !categorySlug) {
    return null
  }
  const clearCacheForDev = ''
  if (process.env.NEXT_PUBLIC_ENV !== 'production') {
    // clearCacheForDev = '?clear';
  }


  //Retrieve product features from Notion DB
  const resNotion = await fetch(process.env.NEXT_PUBLIC_LARAVEL_URL + '/api/db/' + dbID + clearCacheForDev, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: '{"property":"Slug","rich_text":{"equals":"' + categorySlug + '"}}'
  });
  if (resNotion.status !== 200) {
    return null
  }

  const seoContent = await resNotion.json();
  if (!seoContent) {
    return null
  }
  if (seoContent.error) {
    console.error('getCategorySeoContent.error', seoContent.error);
    console.error(seoContent);
    return null
  }

  if (seoContent.length === 0) {
    //console.log('getCategorySeoContent:  seoContent.length === 0');
    return null
  }

  //console.log('getCategorySeoContent : ' + process.env.NEXT_PUBLIC_LARAVEL_URL + '/api/page/' + seoContent[0].id);
  if (seoContent[0].id === undefined) {
    console.error('seoContent[0].id === undefined ', seoContent.error, resNotion.text());
    return null
  }
  //console.log('faq', process.env.NEXT_PUBLIC_LARAVEL_URL + '/api/page/' + seoContent[0].id);
  const resNotionPage = await fetch(process.env.NEXT_PUBLIC_LARAVEL_URL + '/api/page/' + seoContent[0].id + clearCacheForDev, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: '{"heading_2":"seo-title-1", "heading_3":"seo-title-2" }'
  });


  if (resNotionPage.status !== 200) {
    return null
  }
  const pageContent = await resNotionPage.json();
  if (!pageContent) {
    return null
  }
  if (pageContent.error) {
    console.error('getCategorySeoContent:  pageContent.error', pageContent.error);
    return null
  }
  //remove sortIndex from content
  return pageContent;


}
export const getPageDataFromLaravel = async (categorySlug: string | undefined, locale: string | undefined, pageSlug: string | undefined): Promise<any | null> => {

  if (!categorySlug || !locale) {
    return null
  }

  let url = `${process.env.NEXT_PUBLIC_LARAVEL_URL}/ecommerce-data/${categorySlug}?region=${locale}`;

  if (pageSlug) {
    url += `&slug=${pageSlug}`;
  }

  const resLaravel = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
  });

  if (resLaravel.status !== 200) {
    return null
  }

  const pageData = await resLaravel.json();
  return pageData;
}

/**
 * Fetches category data from Laravel backend with proper typing
 * @param categorySlug The category identifier
 * @param locale The region/locale code
 * @param pageSlug Optional page slug for specific pages
 * @returns Promise containing the CategoryData or null if not found
 */
export const getCategoryData = async (
  categorySlug: string | undefined,
  locale: string | undefined,
  pageSlug?: string
): Promise<CategoryData | null> => {
  if (!categorySlug || !locale) {
    return null;
  }

  let url = `${process.env.NEXT_PUBLIC_LARAVEL_URL}/ecommerce-data/${categorySlug}?region=${locale}`;

  if (pageSlug) {
    url += `&slug=${pageSlug}`;
  }

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
    });

    if (!response.ok) {
      console.error(`Failed to fetch category data: ${response.status} ${response.statusText}`);
      return null;
    }

    const data = await response.json();

    // If data is an array, return the first item
    if (Array.isArray(data) && data.length > 0) {
      return data[0] as CategoryData;
    }

    return data as CategoryData;
  } catch (error) {
    console.error('Error fetching category data:', error);
    return null;
  }
}

export const getFaqSeoTextContent = async (dbID: string | undefined, categorySlug: string | undefined, locale: string | undefined): Promise<any[] | null> => {

  if (!dbID || !categorySlug) {
    return null
  }
  const clearCacheForDev = ''
  if (process.env.NEXT_PUBLIC_ENV !== 'production') {
    // clearCacheForDev = '?clear';
  }

  //Retrieve product features from Notion DB
  const resNotion = await fetch(process.env.NEXT_PUBLIC_LARAVEL_URL + '/api/db/' + dbID + clearCacheForDev, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: '{"property":"SlugCustom","rich_text":{"equals":"' + categorySlug + '"}}'
  });
  if (resNotion.status !== 200) {
    return null
  }

  const seoContent = await resNotion.json();
  if (!seoContent) {
    return null
  }
  if (seoContent.error) {
    console.error('seoContent.error', seoContent.error);
    return null
  }

  let listContent: any[] = [];

  seoContent.map((content: any) => {
    //if this feature is not for this region then skip
    if (content.region.length > 0 && !content.region.includes(locale)) {
      //console.log('not found');
      return null;
    }
    const contentRow = { 'title': content.title, 'id': content.id, 'sortIndex': content.sortIndex };
    listContent.push(contentRow);
  });

  //sort listContent by sortIndex
  listContent = listContent.sort((a, b) => (a.sortIndex > b.sortIndex) ? 1 : -1);

  //fetch from await fetch(process.env.NEXT_PUBLIC_LARAVEL_URL + '/api/page/' + dbID); for each content with promise.all
  const promises = listContent.map(async (content: any) => {
    if (content.id === undefined) {
      return null
    }
    const requestUrl = process.env.NEXT_PUBLIC_LARAVEL_URL + '/api/page/' + content.id + clearCacheForDev;
    //console.log('getFaqSeoTextContent: ' + process.env.NEXT_PUBLIC_LARAVEL_URL + '/api/page/' + content.id + clearCacheForDev)
    const resNotion = await fetch(requestUrl, { cache: 'no-store' });
    if (resNotion.status !== 200) {
      return { error: 'Non 200 status, Status: ' + resNotion.status + ', Text: ' + resNotion.statusText + ', URL: ' + requestUrl };
    }
    const pageContent = await resNotion.json();
    if (!pageContent) {
      return { error: 'Empty page content ' + resNotion.text() + ', URL: ' + requestUrl };
    }
    if (pageContent.error) {
      console.log('pageContent.error', pageContent.error);
      return { error: 'pageContent.error ' + pageContent.error + ', URL: ' + requestUrl };
    }
    //remove sortIndex from content
    return { question: content.title, answer: pageContent };
  });

  return await Promise.all(promises);
}

export function extractImgAttributesAndRemoveTag(htmlString: string) {
  // Regex to find the <img> tag
  const imgTagRegex = /<img [^>]*src="[^"]+"[^>]*>/;
  const imgTagMatch = htmlString.match(imgTagRegex);

  if (!imgTagMatch) {
    return { attributes: null, updatedHtmlString: htmlString };
  }

  const imgTag = imgTagMatch[0];

  // Extract attributes
  const attributeRegex = /(\w+)=["']([^"']+)["']/g;
  let match;
  const attributes: { [key: string]: string } = {};
  while ((match = attributeRegex.exec(imgTag)) !== null) {
    attributes[match[1]] = match[2];
  }

  // Remove the <img> tag from the HTML string
  const updatedHtmlString = htmlString.replace(imgTag, '');

  return { attributes, updatedHtmlString };
}

//getISODate will return a string in ISO format
//date is optional, if not provided, current date will be used


export const getISODateNow = () => {
  const date = new Date();
  return getISODate({ date });
};

export const getISODate = ({ date }: {
  date?: Date;
}) => {
  if (!date) {
    date = new Date();
  }
  const tzo = -date.getTimezoneOffset(),
    dif = tzo >= 0 ? '+' : '-',
    pad = (num: number) => {
      const norm = Math.floor(Math.abs(num));
      return (norm < 10 ? '0' : '') + norm;
    };
  return (
    date.getFullYear() +
    '-' +
    pad(date.getMonth() + 1) +
    '-' +
    pad(date.getDate()) +
    'T' +
    pad(date.getHours()) +
    ':' +
    pad(date.getMinutes()) +
    ':' +
    pad(date.getSeconds()) +
    dif +
    pad(tzo / 60) +
    ':' +
    pad(tzo % 60)
  );
};

export const getPageBanners = async (slug: string, locale: string | undefined): Promise<PageBannersResponse | null> => {
  try {
    const url = `${process.env.NEXT_PUBLIC_LARAVEL_URL}/ecommerce-data/page-banners?region=${locale}&slug=${slug}`;
    const response = await fetch(url, { cache: 'no-store' });

    if (!response.ok) {
      console.error(`Failed to fetch page banners. Status: ${response.status}`);
      return null;
    }

    const data: PageBannersResponse = await response.json();

    // Transform video banner to videosByRegion format if present
    if (data.page_banners) {
      data.page_banners = data.page_banners.map(banner => {
        if (banner && banner.type === 'videoBanner') {
          return {
            ...banner,
            type: 'videosByRegion',
            background_video: banner.background_video || '',
            mobile_background_video: banner.mobile_background_video || '',
            background_image: banner.background_image || '',
            mobile_background_image: banner.mobile_background_image || '',
            href: banner.button_url || '/shop'
          };
        }

        // Add welcome campaign discount to firstOrderBanner
        if (banner && banner.type === 'firstOrderBanner' && locale) {
          const welcomeCampaign = getWelcomeCampaing(locale);
          if (banner.button_url) {
            banner.button_url = `/${welcomeCampaign.slug}`;
          }
        }

        return banner;
      });

      // Filter banners by region
      data.page_banners = data.page_banners.filter(banner => {
        return banner.regions.includes(locale === 'international' ? 'International' :
          locale === 'au' ? 'Australia' :
            locale === 'nz' ? 'New Zealand' :
              locale === 'ca' ? 'Canada' : 'International');
      });

      // Sort banners by sort_index if present
      data.page_banners.sort((a, b) => {
        if (a.sort_index === null && b.sort_index === null) return 0;
        if (a.sort_index === null) return 1;
        if (b.sort_index === null) return -1;
        return a.sort_index - b.sort_index;
      });
    }

    return data;
  } catch (error) {
    console.error('Error fetching page banners:', error);
    return null;
  }
};