export const cartString = `
cart {
    appliedCoupons {
      code
      discountAmount
      discountTax
      description
    }
    appliedGiftUp{
      code
      discountAmount
      totalDiscountAmount
    }
    availableShippingMethods {
      rates {
        cost
        id
        label
        methodId
      }
    }
    displayPricesIncludeTax
    subtotal
    subtotalTax
    shippingTotal
    shippingTax
    discountTotal
    total
    totalTax
    chosenShippingMethods
    discountDetails
    contents(last: 100) {
      nodes {
        subtotal
        subtotalTax
        key
        quantity
        product {
          node {
            name
            slug
            databaseId
            image {
              mediaItemUrl
            }
            ... on VariableProduct {
              price
            }
            ... on CompositeProduct {
              price
            }
            ... on SimpleProduct {
              price
              productImageList {
                productImages {
                  fieldGroupName
                  imagePath
                }
              }
            }
          }
        }
        variation {
          node {
            name
            slug
            databaseId
            price
            image {
              mediaItemUrl
            }
            attributes {
              nodes {
                name
                value
                label
              }
            }
          }
        }
        extraData {
          value
          key
        }
        key
        quantity
      }
    }
}
  `;


export const cartStringWithRecalculate = `
cart(recalculateTotals: true) {
    appliedCoupons {
      code
      discountAmount
      discountTax
      description
    }
    appliedGiftUp{
      code
      discountAmount
      totalDiscountAmount
    }
    availableShippingMethods {
      rates {
        cost
        id
        label
        methodId
      }
    }
    displayPricesIncludeTax
    subtotal
    subtotalTax
    shippingTotal
    shippingTax
    discountTotal
    total
    totalTax
    chosenShippingMethods
    discountDetails
    contents(last: 100) {
      nodes {
        subtotal
        subtotalTax
        key
        quantity
        product {
          node {
            name
            slug
            databaseId
            image {
              mediaItemUrl
            }
            ... on VariableProduct {
              price
            }
            ... on CompositeProduct {
              price
            }
            ... on SimpleProduct {
              price
              productImageList {
                productImages {
                  fieldGroupName
                  imagePath
                }
              }
            }
          }
        }
        variation {
          node {
            name
            slug
            databaseId
            price
            image {
              mediaItemUrl
            }
            attributes {
              nodes {
                name
                value
                label
              }
            }
          }
        }
        extraData {
          value
          key
        }
        key
        quantity
      }
    }
}
  `;
