import gql from 'graphql-tag';
import { cartString } from '../cartString';

export const UPDATE_CUSTOMER = gql`
  mutation UpdateCustomerMethod($input: UpdateCustomerInput!) {
    updateCustomer(input: $input) {
      customer {
        id
        shipping {
          address1
          address2
          city
          company
          country
          email
          firstName
          lastName
          postcode
          phone
          state
        }
      }
    }
  }
`;

export const UPDATE_CART_CHOSEN_SHIPPING = gql`
  mutation UpdateShippingMethod($input: UpdateShippingMethodInput!) {
    updateShippingMethod(input: $input) {
      ${cartString}
    }
  }
`;

export const CREATE_CHECKOUT = `
  mutation CreateCheckout($input: CheckoutInput!) {
    checkout(input: $input) {
      result
      order {
        id
        couponLines {
          nodes {
            code
            discount
            discountTax
          }
        }
        needsProcessing
        needsPayment
        paymentMethod
        paymentMethodTitle
        status
        shipping {
          address1
          address2
          city
          country
          email
          firstName
          lastName
          phone
          postcode
          state
        }
        billing {
          email
          phone
        }
        currency
        date
        orderNumber
        paymentMethodTitle
        total
        subtotal
        shippingTotal
        discountTotal
        totalTax
        shippingTax
        lineItems {
          nodes {
            quantity
          }
        }
      }
    }
  }
`;
