import gql from 'graphql-tag';

export const LOGIN_METHOD = gql`
  mutation LoginUser($input: LoginInput!) {
    login(input: $input) {
      authTokens {
        region
        token
      }
      user {
        ID
        display_name
        first_name
        email
        verify
        phone
        gender
        birth_date
        new_opt_in
        channel_email
        channel_sms
        channel_phone
        customer_id
        order_count
      }
    }
  }
`;

export const REGISTER_METHOD = gql`
  mutation RegisterUser($input: RegisterInput!) {
    register(input: $input) {
      authTokens {
        region
        token
      }
      user {
        ID
        display_name
        email
        verify
        phone
        gender
        birth_date
        new_opt_in
        channel_email
        channel_sms
        channel_phone
        customer_id
        order_count
      }
    }
  }
`;

export const VERIFY_METHOD = gql`
  mutation VerifyUser($input: VerifyInput!) {
    verify(input: $input) {
      user {
        ID
        display_name
        email
        verify
        phone
        gender
        birth_date
        new_opt_in
        channel_email
        channel_sms
        channel_phone
        customer_id
        order_count
      }
    }
  }
`;

export const RESEND_METHOD = gql`
  mutation resendVerifyUser($input: resendVerifyInput!) {
    resendVerify(input: $input) {
      user {
        ID
        display_name
        email
        verify
        phone
        gender
        birth_date
        new_opt_in
        channel_email
        channel_sms
        channel_phone
        customer_id
        order_count
      }
    }
  }
`;

export const FINGERPRINT_LOGIN_METHOD = gql`
  mutation fingerPrintLoginUser($input: fingerPrintLoginInput!) {
    fingerPrintLogin(input: $input) {
      authTokens {
        region
        token
      }
      user {
        ID
        display_name
        email
        verify
        phone
        gender
        birth_date
        new_opt_in
        channel_email
        channel_sms
        channel_phone
        customer_id
        order_count
      }
    }
  }
`;

export const FINGERPRINT_LOGOUT_METHOD = gql`
  mutation fingerPrintLogoutUser($input: fingerPrintLoginInput!) {
    fingerPrintLogout(input: $input) {
      message
    }
  }
`;

export const FINGERPRINT_CHECK_PASSWORD_METHOD = gql`
  mutation checkFingerPrintPasswordUser(
    $input: checkfingerPrintPasswordInput!
  ) {
    checkFingerPrintPassword(input: $input) {
      message
    }
  }
`;

export const PASSWORD_RESET_METHOD = gql`
  mutation passwordResetUser($input: passwordResetInput!) {
    passwordReset(input: $input) {
      message
    }
  }
`;

export const PASSWORD_RESET_CHANGE_METHOD = gql`
  mutation passwordResetChangeUser($input: passwordResetChangeInput!) {
    passwordResetChange(input: $input) {
      message
    }
  }
`;
